import * as React from 'react';
import { Typography } from '@mui/material';
import { GenericQuoteForm } from './generic-quote-form';
import { MgfModal } from '../../modal/mgf-modal';

export function GenericQuoteModal(props: any) {

  return (
    <MgfModal ModalProps={props.ModalProps}>
      <Typography variant="h5" sx={{ mb: 4 }}>
        Richiedi un preventivo
      </Typography>
      <GenericQuoteForm onQuoteSent={props.onQuoteSent} />
    </MgfModal>
  );
}
