import { lightBlue } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';


export const mgfTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: "#D82427"
    },
    secondary: lightBlue,
  },
  shape: {
    borderRadius: 0,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1450,
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        size: "large",
        color: "inherit",
      },
      styleOverrides: {
        root: {
          textTransform: "none",
          fontWeight: "bold",
          borderWidth: 2,
          '&:hover': {
            borderWidth: 2,
          }
        }
      }
    },
    MuiInputBase: {
      defaultProps: {
        color: "secondary",
      }
    },
    MuiInputLabel: {
      defaultProps: {
        color: "secondary"
      }
    },
    MuiAppBar: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          backgroundColor: "rgba(0,0,0,0.8)",
          borderBottom: "1px solid rgba(255,255,255,0.2)"
        }
      }
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          // backgroundColor: "rgba(255,255,255,0.05)",
        }
      }
    }
  },
});