import { Box, IconButton, Snackbar, Toolbar, Typography } from "@mui/material";
import React from "react";
import { LanguageSelector } from "../language-selector/language-selector";
import { InternalLink } from "../links/internal_link";
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import MgfDrawer from "./drawer/mgf-drawer";
import { GenericQuoteModal } from "../quote/generic/generic-quote-modal";
import { QuoteResponseModal } from "../quote/quote-response-modal";
import { NavButton } from "./nav-button";
import { ReactComponent as LogoSvg } from "../../../assets/logo/logo-white.svg";
import { MgfSnackbar } from "../snackbar/mgf-snackbar";
const space = 10;


export function MainToolbar(props: any) {
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [quoteModalOpen, setQuoteModalOpen] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState({
    open: false,
    message: "",
    success: false,
  });

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar((snackbar) => ({
      open: false,
      message: "",
      success: snackbar.success
    }))
  };

  const toggleDrawer = (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    const newState = !drawerOpen;
    setDrawerOpen(newState);
  };

  const handleLanguageChange = (language: string) => {

  }

  const handleQuoteSent = (success: boolean) => {
    setQuoteModalOpen(false);
    setSnackbar({
      open: true,
      message: success ? "Richiesta inviata" : "Impossibile inviare la richiesta",
      success: success,
    });
  }

  return (
    <Toolbar sx={{
      // border: "1px solid orange",
    }} onClick={() => drawerOpen && setDrawerOpen(false)}>
      <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
        <Box sx={{
          display: "flex",
          alignItems: "center",
          pt: 1
        }}>
          <InternalLink to="/">
            <LogoSvg height={60} />
          </InternalLink>
        </Box>
      </Typography>
      {/* normal screen */}
      {/* <Box sx={{
        display: {
          xs: "none",
          md: "flex"
        }
      }}>
        <NavButton
          onClick={() => setQuoteModalOpen(true)}>
          Preventivo
        </NavButton>
        <NavButton onClick={() => navigate("/catalog")}>
          Catalogo
        </NavButton>
        <NavButton onClick={() => navigate("/#about_us")}>
          Chi Siamo
        </NavButton>
        <NavButton onClick={() => navigate("/#contacts")}>
          Contatti
        </NavButton>
        <Box sx={{ width: space }} />
        <LanguageSelector onLanguageChange={handleLanguageChange} />
      </Box> */}
      {/* xs screen */}
      {/* <Box sx={{
        display: {
          xs: "flex",
          md: "none"
        }
      }}>
        <LanguageSelector onLanguageChange={handleLanguageChange} />
        <IconButton
          sx={{
            display: {
              md: "none"
            }
          }}
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={toggleDrawer}
        >
          {drawerOpen ? <CloseOutlinedIcon /> : <MenuIcon />}
        </IconButton>
      </Box> */}
      <GenericQuoteModal
        onQuoteSent={handleQuoteSent}
        ModalProps={{
          open: quoteModalOpen,
          onClose: () => setQuoteModalOpen(false)
        }} />
      <MgfDrawer
        onShowQuote={() => setQuoteModalOpen(true)}
        open={drawerOpen}
        toggleDrawer={toggleDrawer} />
      <MgfSnackbar snackbar={snackbar} handleClose={handleClose} />
    </Toolbar>
  )
}