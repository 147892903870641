import { Box, Snackbar, TextField } from "@mui/material";
import React from "react";
import { LoadingButton } from "@mui/lab";
import { GenericQuote } from "../../../types/types";
import { validation } from "../validation";
import { PhoneNumberInput } from "../../phone-number-input/phone-number-input";
import api from "../../../api/api";


const emptyQuote = {
  name: "",
  surname: "",
  email: "",
  phone: {
    prefix: 39,
  },
  message: "",
} as GenericQuote

export function GenericQuoteForm(props: any) {
  const [quote, setQuote] = React.useState(emptyQuote)
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState({} as any);

  const sendQuote = async () => {
    setLoading(true);
    console.log(quote);
    const res = await api.genericQuotes.post(quote);
    const success = res.status.error === null;
    setLoading(false);
    setQuote(emptyQuote);
    props.onQuoteSent(success);
  }

  const handleNameChange = (e: any) => {
    const name = e.target.value;
    setQuote({ ...quote, name: name });
    const msg = validation.validateName(name);
    msg ? setError({ ...error, name: msg }) : setError({ ...error, name: null });
  }

  const handleSurnameChange = (e: any) => {
    const surname = e.target.value;
    setQuote({ ...quote, surname: surname });
    const msg = validation.validateSurname(surname);
    msg ? setError({ ...error, surname: msg }) : setError({ ...error, surname: null });
  }

  const handleEmailChange = (e: any) => {
    const email = e.target.value;
    setQuote({ ...quote, email: email });
    const msg = validation.validateEmail(email);
    msg ? setError({ ...error, email: msg }) : setError({ ...error, email: null });
  }

  const handleNumberChange = (number: number) => {
    setQuote({ ...quote, phone: { ...quote.phone, number: number } });
    const msg = validation.validatePhoneNumber(number);
    msg ? setError({ ...error, phone: msg }) : setError({ ...error, phone: null });
  }

  const handlePrefixChange = (prefix: number) => {
    setQuote({ ...quote, phone: { ...quote.phone, prefix: prefix } });
  }

  const handleMessageChange = (e: any) => {
    const message = e.target.value;
    setQuote({ ...quote, message: message });
    const msg = validation.validateMessage(message);
    msg ? setError({ ...error, message: msg }) : setError({ ...error, message: null });
  }

  const sendBtnEnabled = () => {
    const quoteFields = quote.name && quote.surname && quote.email && quote.phone && quote.message;
    return !errors() && quoteFields;
  }

  const errors = () => {
    return error.name || error.surname || error.email || error.phone || error.message;
  }

  return (
    <Box sx={{
      '& .MuiTextField-root': { mb: 3 }
    }}>
      <TextField
        error={Boolean(error?.name)}
        helperText={error?.name}
        required
        fullWidth
        size="small"
        label="Nome"
        value={quote.name}
        onChange={handleNameChange}
      />
      <TextField
        error={Boolean(error?.surname)}
        helperText={error?.surname}
        required
        fullWidth
        size="small"
        label="Cognome"
        value={quote.surname}
        onChange={handleSurnameChange}
      />
      <TextField
        error={Boolean(error?.email)}
        helperText={error?.email}
        required
        fullWidth
        size="small"
        label="Email"
        value={quote.email}
        onChange={handleEmailChange}
      />
      <PhoneNumberInput
        phone={quote.phone}
        onPrefixChange={handlePrefixChange}
        onNumberChange={handleNumberChange}
        TextFieldProps={{
          error: Boolean(error?.phone),
          helperText: error?.phone,
        }}
      />
      <TextField
        error={Boolean(error?.message)}
        helperText={error?.message}
        required
        fullWidth
        multiline
        rows={4}
        label="Messaggio"
        size="small"
        value={quote.message}
        onChange={handleMessageChange}
      />
      <LoadingButton
        disabled={!sendBtnEnabled()}
        fullWidth
        loading={loading}
        variant="outlined"
        onClick={sendQuote}>
        Richiedi preventivo
      </LoadingButton>
    </Box>
  )
}