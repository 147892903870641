import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { MgfContainer } from "../../../common/components/mgf-container/mgf-container";
import { GenericQuoteModal } from "../../../common/components/quote/generic/generic-quote-modal";
import { QuoteResponseModal } from "../../../common/components/quote/quote-response-modal";
import { TypeAnimation } from 'react-type-animation';
import "./cover.css";


const baseSx = {
  heading: {
    transition: "all 500ms ease-in 1000ms"
  },
  subtitle: {
    transition: "all 500ms ease-in 1100ms"
  },
  button: {
    transition: "all 500ms ease-in 1500ms"
  },
}

const waitPeriod = 1800; //ms

const headingSequence = [
  "AUTO SPORTIVE", waitPeriod,
  "LUXURY CAR", waitPeriod,
  "SUV", waitPeriod,
  "AUTO ELETTRICHE", waitPeriod,
  "CITY CAR", waitPeriod,
  "BERLINE", waitPeriod,
]

export function CoverIntro(props: any) {
  const [quoteModalOpen, setQuoteModalOpen] = useState(false);
  const [quoteResponseModalOpen, setQuoteResponseModalOpen] = useState(false);
  const [quoteSentOutput, setQuoteSentOutput] = useState(false);
  const [sx, setSx] = useState({
    heading: {
      opacity: 0,
      mt: -10
    },
    subtitle: {
      opacity: 0,
      mt: -10
    },
    button: {
      opacity: 0,
      mt: 20
    },
  });

  useEffect(() => {
    setSx({
      heading: {
        opacity: 1,
        mt: 0
      },
      subtitle: {
        opacity: 1,
        mt: 4
      },
      button: {
        opacity: 1,
        mt: 4,
      }
    })
  }, [])

  const handleQuoteSent = (success: boolean) => {
    setQuoteModalOpen(false);
    setQuoteSentOutput(success);
    setQuoteResponseModalOpen(true);
  }

  return (
    <MgfContainer
      sx={{
        // border: "1px solid white",
        pt: {
          xs: "15vh",
          md: "30vh"
        },
        textAlign: {
          xs: "center",
          md: "left"
        },
      }}>
      <Box sx={{
        ...baseSx?.heading,
        ...sx?.heading,
      }}>

        <Typography
          variant="h2"
          component="h1">
          New Website
        </Typography>
        <Typography
          variant="h2"
          component="h1"
          fontWeight="bold">
          Coming Soon...
        </Typography>
        {/* <TypeAnimation
          className="typeanimation"
          sequence={headingSequence}
          wrapper="div"
          cursor={false}
          speed={30}
          repeat={0}
          style={{
            fontWeight: "bold",
            fontSize: "3.75rem",
            lineHeight: 1.2,
          }}
        /> */}
      </Box>
      <Box
        sx={{
          ...baseSx.subtitle,
          ...sx.subtitle,
          display: {
            xs: "none",
            md: "block"
          },
          // mt: 4
        }}>
        <Typography
          variant="h6"
          component="div"
        >
          We're working on it 🛠️
        </Typography>
      </Box>
      {/* <Box sx={{
        ...baseSx.button,
        ...sx.button,
        // mt: 4,
      }}>
        <Button variant="outlined" onClick={() => setQuoteModalOpen(true)}>
          Richiedi un preventivo
        </Button>
      </Box> */}
      <GenericQuoteModal
        onQuoteSent={handleQuoteSent}
        ModalProps={{
          open: quoteModalOpen,
          onClose: () => setQuoteModalOpen(false)
        }} />
      <QuoteResponseModal
        success={quoteSentOutput}
        ModalProps={{
          open: quoteResponseModalOpen,
          onClose: () => setQuoteResponseModalOpen(false)
        }} />
    </MgfContainer>
  )
}