import * as React from 'react';
import { countries } from './countries';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { Country } from './country';

export function CountrySelect(props: any) {

  const handleChange = (e: SelectChangeEvent) => {
    const c = countries.find(c => c.code === e.target.value)
    props.onChange(c?.phone);
  };

  const getCountryCode = (prefix: number) => {
    return countries.find(c => c.phone === prefix?.toString())?.code;
  }

  const renderCountry = (code: string) => {
    const c = countries.find(c => c.code === code);
    return <Country country={c} label={`(+${c?.phone})`} />
  }

  return (
    <FormControl>
      <InputLabel id="countries-select">Prefisso</InputLabel>
      <Select
        size="small"
        labelId="countries-select"
        id="countries-select"
        value={getCountryCode(props.value)}
        label="Prefisso"
        onChange={handleChange}
        renderValue={renderCountry}
        MenuProps={{
          sx: {
            maxHeight: 300
          }
        }}
        IconComponent={() => null}
      >
        {countries.map(c => <MenuItem key={c.code} value={c.code}>
          <Country country={c} label={`(+${c?.phone}) ${c.label}`} />
        </MenuItem>)}
      </Select>
    </FormControl>
  );
}



/*


    <Autocomplete
      id="country-select"
      sx={{ width: 100 }}
      options={countries}
      autoHighlight
      getOptionLabel={(option) => option.label}
      renderOption={(props, option) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            alt=""
          />
          (+{option.phone}) {option.label}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Choose a country"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
    />


 */

