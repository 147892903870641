import React, { useEffect } from "react";
import { Cover } from "./cover/cover";


export function ComingSoon(props: any) {

  return (
    <>
      <Cover />
    </>
  )
}