
export function sleep(seconds: number) {
  return new Promise(resolve => setTimeout(resolve, seconds * 1000));
}

export function is_empty(s: string) {
  return !s.replace(/\s/g, '').length
}

export function is_valid_email(email: string) {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}

export function random_bool() {
  return  Math.random() % 2 > 0.5;
}

export function isSuccessStatus(status_code: number) {
  return `${status_code}`.startsWith("2");
}