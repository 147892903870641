import * as React from 'react';
import Modal from '@mui/material/Modal';
import { Box } from '@mui/material';

const sx = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {
    xs: 0.95,
    md: 500,
  },
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  textAlign: "center"
};

export function MgfModal(props: any) {

  return (
    <Modal
      {...props.ModalProps}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={sx}>
        {props.children}
      </Box>
    </Modal>
  );
}
