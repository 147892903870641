import { Snackbar } from "@mui/material";
import React from "react";
import MuiAlert, { AlertProps } from '@mui/material/Alert';


const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export function MgfSnackbar(props: any) {
  return (
    <Snackbar
      open={props.snackbar.open}
      autoHideDuration={2500}
      onClose={props.handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <Alert
        onClose={props.handleClose}
        severity={props.snackbar.success ? "success" : "error"}
        sx={{ width: '100%' }}>
        {props.snackbar.message}
      </Alert>
    </Snackbar>
  )
}