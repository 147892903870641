import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { Toolbar } from '@mui/material';
import { useNavigate } from 'react-router-dom';


const menuTextAlign = "center";

export default function MgfDrawer(props: any) {
  const navigate = useNavigate();

  return (
    <>
      <Drawer
        anchor="right"
        sx={{
          // border: "3px solid yellow",
        }}
        PaperProps={{
          sx: {
            background: "rgba(0,0,0,0.8)"
          }
        }}
        open={props.open}
        onClose={props.toggleDrawer}
      >
      <Toolbar />
        <Box
          sx={{
            width: {
              xs: "100vw",
            },
            // border: "1px solid red",
            background: "none"
          }}
          role="presentation"
          onClick={props.toggleDrawer}
          onKeyDown={props.toggleDrawer}
        >
          {/* need Toolbar for exact same margin top as appbar */}
          <Toolbar />
          <List>
            <ListItem
              key="quote"
              onClick={props.onShowQuote}
              disablePadding>
              <ListItemButton>
                <ListItemText
                  primary="Preventivo"
                  primaryTypographyProps={{ textAlign: menuTextAlign }}
                />
              </ListItemButton>
            </ListItem>
            <ListItem
              key="catalog"
              onClick={() => navigate("/catalog")}
              disablePadding>
              <ListItemButton>
                <ListItemText
                  primary="Catalogo"
                  primaryTypographyProps={{ textAlign: menuTextAlign }}
                />
              </ListItemButton>
            </ListItem>
            <ListItem
              key="about-us"
              onClick={() => navigate("/#about_us")}
              disablePadding>
              <ListItemButton>
                <ListItemText
                  primary="Chi Siamo"
                  primaryTypographyProps={{ textAlign: menuTextAlign }}
                />
              </ListItemButton>
            </ListItem>
            <ListItem
              key="contact"
              onClick={() => navigate("/#contacts")}
              disablePadding>
              <ListItemButton>
                <ListItemText
                  primary="Contatti"
                  primaryTypographyProps={{ textAlign: menuTextAlign }}
                />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </>
  )
}
