import { is_empty, is_valid_email } from "../../utils/utils";

const emptyFieldMsg = "Campo obbligatorio";
const whiteSpaceMsg = "Il campo non può eseere vuoto";

const basicValidation = (s: string, maxChars: number | undefined = undefined) => {
  if (!s) {
    return emptyFieldMsg;
  }
  if (is_empty(s)) {
    return whiteSpaceMsg;
  }
  if (maxChars) {
    if (s.length > maxChars) {
      return `Massimo ${maxChars} caratteri`;
    }
  }
}

const validateName = (s: string) => {
  return basicValidation(s);
}

const validateSurname = (s: string) => {
  return basicValidation(s);
}

const validateEmail = (s: string) => {
  const res = basicValidation(s);
  if (res) return res;
  if (!is_valid_email(s)) {
    return "Email non valida";
  }
}

const validatePhoneNumber = (number: number) => {
  if (number.toString() === "") {
    return emptyFieldMsg;
  }
  if (is_empty(number.toString())) {
    return whiteSpaceMsg;
  }
}

const validateAddress = (s: string) => {
  return basicValidation(s);
}

const validateMessage = (s: string) => {
  return basicValidation(s, 500);
}

const validateOptionalMessage = (msg: string) => {
  if (is_empty(msg) && msg) { // "&& msg" => so no validation error if empty
    return whiteSpaceMsg;
  }
  const maxLength = 500;
  if (msg.length > maxLength) {
    return `Massimo ${maxLength} caratteri`;
  }
}

const validatePickUpLocation = (s: string) => {
  return basicValidation(s);
}

const validateDropOffLocation = (s: string) => {
  return basicValidation(s);
}

export const validation = {
  validateName,
  validateSurname,
  validateEmail,
  validatePhoneNumber,
  validateAddress,
  validateMessage,
  validateOptionalMessage,
  validatePickUpLocation,
  validateDropOffLocation,
}