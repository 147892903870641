import { Car, Filter } from "../../types/types";
import ApiBase from "../base/api.base";


export class CarsApi extends ApiBase {

  constructor(url: string) {
    super(`${url}cars`);
  }

  async search(filter: Filter | undefined) {
    try {
      const res = await fetch(this.url);
      return await res.json();
    }
    catch (e) {
      return {
        status: {
          error: "impossibile eseguire la richiesta"
        }
      }
    }
  }

  async get(id: string | undefined) {
    try {
      const res = await fetch(`${this.url}/${id}`);
      return await res.json();
    }
    catch (e) {
      return {
        status: {
          error: "impossibile eseguire la richiesta"
        }
      }
    }
  }

  async update(car: Car) {
    try {
      const res = await fetch(`${this.url}/${car._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(car),
      });
      return res.json();
    }
    catch (e) {
      return {
        status: {
          error: "impossibile eseguire la richiesta"
        }
      }
    }
  }
}