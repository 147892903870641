import ApiBase from "./base/api.base";
import { CarsApi } from "./resources/cars-api";
import { GenericQuotesApi } from "./resources/generic-quotes-api";
import { QuotesApi } from "./resources/quotes-api";


const baseUrl = process.env.REACT_APP_API_BASE_URL;

class Api extends ApiBase {
  cars: CarsApi = new CarsApi(this.url)
  quotes: QuotesApi = new QuotesApi(this.url)
  genericQuotes: GenericQuotesApi = new GenericQuotesApi(this.url)
}

export default new Api(baseUrl!);