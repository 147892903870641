import { GenericQuote } from "../../types/types";
import ApiBase from "../base/api.base";


export class GenericQuotesApi extends ApiBase {

  constructor(url: string) {
    super(`${url}generic-quotes`);
  }

  async search() {
    try {
      const res = await fetch(this.url);
      return await res.json();
    }
    catch (e) {
      return {
        status: {
          error: "impossibile eseguire la richiesta"
        }
      }
    }
  }

  async get(id: string | undefined) {
    try {
      const res = await fetch(`${this.url}/${id}`);
      return await res.json();
    }
    catch (e) {
      return {
        status: {
          error: "impossibile eseguire la richiesta"
        }
      }
    }
  }

  async post(quote: GenericQuote) {
    try {
      console.log(quote);
      const res = await fetch(this.url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(quote)
      });
      return await res.json();
    }
    catch (e) {
      return {
        status: {
          error: "impossibile eseguire la richiesta"
        }
      }
    }
  }
}