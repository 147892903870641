import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import image from "../../../assets/images/home-cover.jpg";
import { CoverIntro } from "./cover-intro";

const baseSx = {
  transition: "all 500ms ease-in 0ms"
}

export function Cover(props: any) {
  const [sx, setSx] = useState({
    opacity: 0,
  });

  useEffect(() => {
    setSx(sx => ({
      ...sx,
      opacity: 1,
    }))
  }, [])

  return (
    <Box sx={{
      ...baseSx,
      ...sx,
      margin: 0,
      height: "100vh",
      backgroundImage: `url(${image})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: {
        xs: "55% 0%",
        md: "center"
      }
    }}>
      <CoverIntro />
    </Box>
  )
}