import { Box } from "@mui/material";
import React from "react";


export function Country(props: any) {
  return <Box component="div" sx={{ '& > img': { mr: 1, flexShrink: 0 } }}>
    <img
      loading="lazy"
      width="20"
      src={`https://flagcdn.com/w20/${props.country.code.toLowerCase()}.png`}
      srcSet={`https://flagcdn.com/w40/${props.country.code.toLowerCase()}.png 2x`}
      alt=""
    />
    {props.label}
  </Box>
}