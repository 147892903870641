import { Box } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";


export function InternalLink(props: any) {
  return (
    <Box sx={{
      "a": {
        textDecoration: "none",
        color: "inherit",
      },
      display: "inline",
    }}>
      <Link to={props.to}>
        {props.children}
      </Link>
    </Box>
  )
}