import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { Container, CssBaseline, ThemeProvider } from "@mui/material";
import { MgfAppBar } from "./common/components/appbar/mgf-appbar";
import { MgfFooter } from "./common/components/footer/mgf-footer";
import { Home } from "./pages/home/home";
import { Catalog } from "./pages/catalog/catalog";
import { mgfTheme } from "./common/theme/mgf-theme";
import { CarDetail } from "./pages/car-detail/car-detail";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { ConfirmQuote } from "./pages/confirm-quote/confirm-quote";
import { ComingSoon } from "./pages/coming-soon/coming-soon";


export default function App(props: any) {

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProvider theme={mgfTheme}>
          <CssBaseline />
          <BrowserRouter>
            <MgfAppBar />
            <Container
              disableGutters
              maxWidth={false}
              sx={{
                // border: "1px solid red",
              }}>
              <Routes>
                <Route path="/" element={<ComingSoon />} />
                {/* <Route path="/" element={<Home />} />
                <Route path="catalog" element={<Catalog />} />
                <Route path="catalog/:id" element={<CarDetail />} />
                <Route path="confirm-quote" element={<ConfirmQuote />} /> */}
              </Routes>
            </Container>
            {/* <MgfFooter /> */}
          </BrowserRouter>
        </ThemeProvider>
      </LocalizationProvider>
    </div>
  )
}