import * as React from 'react';
import { Button, Typography } from '@mui/material';
import { MgfModal } from "../modal/mgf-modal";
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';

function Content(props: any) {
  return <>
    {props.icon}
    <Typography variant="h5" fontWeight="regular" sx={{ mt: 1 }}>
      {props.title}
    </Typography>
    <Typography variant="body1" sx={{ mt: 2 }}>
      {props.subtitle}
    </Typography>
    <Button
      variant="outlined"
      size="medium"
      onClick={props.onClose}
      sx={{ mt: 3 }}>
      Chiudi
    </Button>
  </>
}

export function QuoteResponseModal(props: any) {

  return (
    <MgfModal ModalProps={props.ModalProps}>
      {props.success ?
        <Content title="Richiesta inviata!"
          subtitle="Ti ricontatteremo a breve."
          onClose={props.ModalProps.onClose}
          icon={<CheckCircleTwoToneIcon fontSize="large" color="success" />} />
        :
        <Content title="Ooops"
          subtitle="Si è verificato un errore nella tua richiesta, riprova più tardi."
          onClose={props.ModalProps.onClose}
          icon={<ErrorTwoToneIcon fontSize='large' color="error" />} />
      }

    </MgfModal>
  );
}
