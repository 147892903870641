import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import { Container } from '@mui/material';
import { MainToolbar } from './main-toolbar';


export function MgfAppBar() {

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Container
          disableGutters
          maxWidth="xl">
            {/* <ContactsToolbar /> */}
            <MainToolbar />
        </Container>
      </AppBar>
    </Box>
  );
}