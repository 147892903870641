import { Container } from "@mui/material";
import React from "react";


export function MgfContainer(props: any) {
  return (
    <Container
      disableGutters
      maxWidth={props.maxWidth || "xl"}
      sx={{
        px: {
          xs: 2,
          md: 4
        },
        ...props.sx,
      }}>
        {props.children}
    </Container>
  )
}