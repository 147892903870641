import { Stack, TextField } from "@mui/material";
import React from "react";
import { CountrySelect } from "./country-select";


export function PhoneNumberInput(props: any) {

  return <Stack direction="row" spacing={1}>
    <CountrySelect
      onChange={(prefix: number) => props.onPrefixChange(prefix)}
      value={props.phone?.prefix}
    />
    <TextField
      type="number"
      required
      fullWidth
      size="small"
      label="Telefono"
      value={props.phone?.number ?? ""}
      onChange={(e) => props.onNumberChange(e.target.value)}
      {...props.TextFieldProps}
    />
  </Stack>
}